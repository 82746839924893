<template>
    <CModal
      :title="'Folio de Reserva: '+reservation.id"
      :color="color"
      :show.sync="showModal"
    >
      <p style="font-weight: bold;">¿Estás seguro que deseas confirmar / cancelar la reservación de boletos?</p>
        <br>
        La reservación será <b>confirmada / cancelada</b> y el proceso ya no se puede deshacer.
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cerrar</CButton>
          <CButton @click="cancelReservation" color="danger">Cancelar Reserva</CButton>
          <CButton @click="confirmReservation" color="success">Confirmar Pago</CButton>
        </template>
    </CModal>
</template>

<script>
import raffles from '../../../services/raffles';

import { DatePicker } from 'v-calendar'

import moment from 'moment';
import moment_timezone from 'moment-timezone'

moment.tz.setDefault('America/Mexico_city')

let today = new moment();
today = new moment(today.format("YYYY-MM-DD HH:m:ss")); 

export default {
  name: 'ReservationModal',
  components: {
    'v-date-picker': DatePicker
  },
  props: {

  },
  data () {
    return {
      showModal: false,
      title: "Reserva de Boletos",
      color: "info",
      reservation: false
    }
  },
  mounted: async function() {
    
  },
  methods: {
    openModal (item) {
      this.showModal = true;
      this.color = "info";
      this.title = 'Reserva de Boletos';

      this.reservation = item;
    },
    confirmReservation () {
      this.$emit("confirm", this.reservation );

      this.closeModal();
    },
    cancelReservation () {
      this.$emit("cancel", this.reservation );

      this.closeModal();
    },
    closeModal () {
      this.showModal = false;
    },
  },
  computed: {

  },
}
</script>