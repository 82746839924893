<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <div class="card-header-actions">
        </div>
        <a href="/#/control/raffles"><b>{{raffle.name}}</b></a><br />Reservados ( Total: {{items.length}} )
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        :tableFilter="tableFilter"
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="small ? 100 : 50"
        :dark="dark"
        pagination
      >
        <template #id="{item}"><td class="text-center w100">{{item.id}}</td></template>
        <template #date="{item}"><td class="text-center w100">{{item.date | date}}</td></template>
        <template #deadline="{item}"><td class="text-center w200">{{item.deadline | datetime}}</td></template>
        <template #gambler_whatsapp="{item}"><td class="text-center w300">
        {{item.gambler_name}}<br />{{item.gambler_whatsapp}}<br />{{item.gambler_state}}
        <br />
        <a href="javascript:;" @click="sendWhatsapp(item.gambler_whatsapp)"><img src="/whatsapp.jpg" style="width: 60%" alt=""></a>
        </td></template>
        <template #tickets="{item}"><td class="text-center">
          Número de Boletos: {{item.tickets.length}} ( $ {{item.tickets.length * raffle.ticket_price | currency}} )<br /><br />
          <button v-for="ticket in item.tickets" type="button" class="btn btn-outline-dark" style="margin: 1px; font-size: 12px;">{{ticket}}</button>
        </td></template>
        <template #status="{item}"><td class="text-center w200">{{item.status == 'available' ? '-' : (item.status == 'reserved' ? 'RESERVADO' : (item.status == 'paid' ? 'CONFIRMADO' : 'CANCELADO'))}}</td></template>
        <template #actions="{item}">
          <td class="table_actions">
            <CButton color="info" size="sm" @click="reservationModal(item)" v-if="item.status == 'reserved'"><CIcon name="cil-check"/></CButton>
            <CButton color="danger" size="sm" @click="deleteModal(item)"><CIcon name="cil-trash"/></CButton>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
    <ReservationModal ref="reservationModal" @confirm="confirmProcess" @cancel="cancelProcess"></ReservationModal>
    <DeleteModal ref="deleteModal" @delete="deleteProcess"></DeleteModal>
  </CCard>
</template>

<script>
import ReservationModal from './ReservationModal.vue'
import DeleteModal from '../../global/DeleteModal.vue'
import ws from '../../../services/raffles';
import store from '../../../store'

export default {
  name: 'ReservationsTable',
  components: { ReservationModal, DeleteModal },
  props: {
    items: Array,
    raffle: Object,
    permissions: Array,
    fields: {
      type: Array,
      default () {
        return [
          {key: 'id', label: 'Folio'},
          {key: 'date', label: 'Fecha'},
          {key: 'gambler_whatsapp', label: 'Participante'},
          {key: 'tickets', label: 'Boletos'},
          {key: 'deadline', label: 'Vigencia'},
          {key: 'status', label: 'Estado de la Reserva'},
          {key: 'actions', label: 'Acciones', class: 'actions' },
        ]
      }
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean,
    total: 0
  },
  data() {
    return {
      toasts: [],
      search: "",
      tableFilter: {
        label: 'Buscar: ',
        placeholder: '...'
      }
    }
  },
  methods: {
    reservationModal (item) {
      this.$refs.reservationModal.openModal(item);
    },
    deleteModal (item) {
      this.$refs.deleteModal.show("Eliminar Reserva", item.id, "Folio: "+item.id);
    },
    async searching () {
      this.loading();
      let response = await ws.searchingReservations(this.raffle.code, {search: this.search});
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.loaded();
    },
    async sendWhatsapp (whatsapp) {
      this.loading();
      let response = await ws.sendWhatsapp(this.raffle.code, {whatsapp: whatsapp});
      
      if(response.type == "success"){
        let whatsapp_url = response.data;

        window.open(whatsapp_url, '_blank');
      }

      this.loaded();
    },
    async confirmProcess(reservation) {
      this.loading();
      let response = await ws.confirmReservation(this.raffle.code, {id: reservation.id});
      
      if(response.type == "success"){
        if(this.search !== undefined && this.search != ''){
          response = await ws.searchingReservations(this.raffle.code, {search: this.search});

          if(response.type == "success"){
            this.$emit("refresh", response.data );
          }
        }
        else{
          this.$emit("refresh", response.data );
        }
      }

      this.showToast(response.type, response.message);
      this.loaded();
    },
    async cancelProcess(reservation) {
      this.loading();
      let response = await ws.cancelReservation(this.raffle.code, {id: reservation.id});

      if(response.type == "success"){
        if(this.search !== undefined && this.search != ''){
          response = await ws.searchingReservations(this.raffle.code, {search: this.search});

          if(response.type == "success"){
            this.$emit("refresh", response.data );
          }
        }
        else{
          this.$emit("refresh", response.data );
        }
      }

      this.showToast(response.type, response.message);
      this.loaded();
    },
    async deleteProcess(reservation_id) {
      this.loading();
      let response = await ws.deleteReservation(this.raffle.code, {id: reservation_id});
      
      if(response.type == "success"){
        if(this.search !== undefined && this.search != ''){
          response = await ws.searchingReservations(this.raffle.code, {search: this.search});

          if(response.type == "success"){
            this.$emit("refresh", response.data );
          }
        }
        else{
          this.$emit("refresh", response.data );
        }
      }

      this.showToast(response.type, response.message);
      this.loaded();
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
