<template>
  <div>
    <CRow>
      <CCol sm="12">
        <ReservationsTable
          :raffle="raffle"
          :items="reservations"
          :total="total"
          hover
          striped
          border
          small
          fixed
          @refresh="refreshItems"
          v-if="raffle"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import ReservationsTable from '../../components/administrator/raffles/ReservationsTable.vue'
import raffles from '../../services/raffles';
import store from '../../store'

export default {
  name: 'Reservations',
  components: { ReservationsTable },
  data: function () {
		return {
            raffle: null,
            total: 0,
            reservations: []
        }
  },
  mounted: async function() {
    this.loading();

    this.code = await this.$route.params.code;

    let response = await raffles.getRaffle(this.code);

    if(response.type == "success"){
        this.raffle = response.data;
    }

    let response2 = await raffles.getReservations(this.code);

    if(response2.type == "success"){
      this.reservations = response2.data;

      this.reservations.forEach(async (reservation) => {
        this.total++;
      });
    }

    this.loaded();
  },
  methods: {
    async refreshItems (items){
      this.loading();
      this.reservations = await items;
      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
